import React from "react";

function ErrorPages() {
    return (

            <section className="bg-[url('https://firebasestorage.googleapis.com/v0/b/recursos-31efd.appspot.com/o/BG%2Fbg-unnder.png?alt=media&token=98dfa75e-28f8-48ea-a968-51ad91b53365')] bg-cover bg-center">
                <div className="w-full h-screen flex justify-center items-center p-2">
                    <div className="w-1/2">
                        <h1 className="mb-5 text-[40px] font-bold text-yellow-500 capitalize">
                            🚧 Website under construction 🚧
                        </h1>
                        <p className="text-black text-base font-semibold">
                            We are working to improve our site, come back soon!
                        </p>
                    </div>
                    <div>
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/recursos-31efd.appspot.com/o/BG%2Funderconstruction.png?alt=media&token=1e64685f-d90c-4ece-af4c-11972df11327"
                            alt="Under Construction"
                            className="w-[550px] h-auto"
                        />
                    </div>
                </div>
            </section>

    );
}

export default ErrorPages;